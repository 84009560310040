<template>
  <div class='card'>
    <div class='container'>
      <b-modal ref='addPreOwnedBannersModal' hide-footer>
        <addPreOwnedBanners @closeAddPreOwnedBanners='toggleAddPreOwnedBanners()' @addPreOwnedBannersSuccess='addPreOwnedBannersSuccess()'></addPreOwnedBanners>
      </b-modal>
      <b-modal ref='editPreOwnedBannersModal' hide-footer>
        <editPreOwnedBanners
          :editingPreOwnedBanners='currentPreOwnedBanners'
          @closeEditPreOwnedBanners='toggleEditPreOwnedBanners()'
          @editPreOwnedBannersSuccess='editPreOwnedBannersSuccess()'
        ></editPreOwnedBanners>
      </b-modal>
      <div class='row'>
        <h2>Pre-Owned Banners</h2>
      </div>
      <div class='row'>
        <button @click='toggleAddPreOwnedBanners()' type='button' class='btn btn-success'>Add</button>
      </div>
      <div class='row'>
        <br>

        <DataBlocks
         
         :showImage='true'
         @itemSelected="editItem"
         :data="items"
         :fields="['Heading']"
       />
       
      </div>
    </div>
  </div>
</template>
<script>
import addPreOwnedBanners from '../../components/addPreOwnedBanners';
import editPreOwnedBanners from '../../components/editPreOwnedBanners';
import DataBlocks from "@/components/custom/dataBlocks.vue";
import VueNotifications from 'vue-notifications';
import {    Datatable  } from '../../mdb/components/Datatable';
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
              {
                label: 'Id',
                field: 'Id',
                sort: 'asc',
              },
              {
                label: 'Heading',
                field: 'Heading',
                sort: 'asc',
              },
              {
                label: 'Copy',
                field: 'Copy',
                sort: 'asc',
              },
              {
                label: 'Image',
                field: 'Image',
                sort: 'asc',
              },
              {
                label: 'Link',
                field: 'Link',
                sort: 'asc',
              },
              {
                label: 'LinkFriendly',
                field: 'LinkFriendly',
                sort: 'asc',
              },
              {
                label: 'LastUpdated',
                field: 'LastUpdated',
                sort: 'asc',
              },
              {
                label: 'ShowText',
                field: 'ShowText',
                sort: 'asc',
              },
      ],
      items: [],
      addPreOwnedBannersOpen: false,
      editPreOwnedBannersOpen: false,
      currentPreOwnedBanners: {}
    };
  },
  components: {
    addPreOwnedBanners,
    editPreOwnedBanners,
    DataBlocks,
  },
  created: function() {
    this.getPreOwnedBanners();
    this.addPreOwnedBannersOpen = false;
    this.editPreOwnedBannersOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
 getPreOwnedBanners() {
      var payload = {
        success: response => {
          this.items = response.data;
        },
        error: error => { miniToastr['error'](error, 'Error', 1000, null)}
      }
      this.$store.dispatch('getPreOwnedBanners', payload);
    },
    editItem(PreOwnedBanners) {
      this.toggleEditPreOwnedBanners();
      this.currentPreOwnedBanners = PreOwnedBanners;
    },
    toggleAddPreOwnedBanners() {
      if(this.addPreOwnedBannersOpen)
      {
        this.$refs.addPreOwnedBannersModal.hide()
      }
      else{
        this.$refs.addPreOwnedBannersModal.show()
      }
      this.addPreOwnedBannersOpen = !this.addPreOwnedBannersOpen;
    },
    addPreOwnedBannersSuccess() {
      this.toggleAddPreOwnedBanners();
      miniToastr['success']('PreOwnedBanners Added', 'Success', 1000, null);
      this.getPreOwnedBanners();
    },
    toggleEditPreOwnedBanners() {
      if(this.editPreOwnedBannersOpen)
      {
        this.$refs.editPreOwnedBannersModal.hide()
      }
      else{
        this.$refs.editPreOwnedBannersModal.show()
      }
      this.editPreOwnedBannersOpen = !this.editPreOwnedBannersOpen;
    },
    editPreOwnedBannersSuccess() {
      this.toggleEditPreOwnedBanners();
      miniToastr['success']('PreOwnedBanners Edited', 'Success', 1000, null);
      this.getPreOwnedBanners();
    }
  }
};
</script>
<style scoped>
.PreOwnedBannersThumbnail {
  height: 50px;
}
</style>
