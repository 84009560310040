<template>
  <div class='container'>
    <h2>Edit Pre-Owned Banner</h2>
    <button @click='save()' type='button' class='btn btn-success'>Save</button>

    <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>

    <button @click='deletePreOwnedBanners()' type='button' class='btn btn-danger'>Delete</button>

    <div class='row'>
      <div class='col-md-6'>
        <label class for='Heading'>Heading</label>
        <input class='form-control' id='Heading' name='Heading' v-model='editingPreOwnedBanners.Heading' placeholder=' '
          tabindex='0' type='text'>

      </div>
      <div class='col-md-6'>
        <label class for='Copy'>Copy</label>
        <input class='form-control' id='Copy' name='Copy' v-model='editingPreOwnedBanners.Copy' placeholder=' '
          tabindex='0' type='text'>

      </div>
    </div>
    <div class="row">
      <div class='col-md-6'>
        <ImageUploader label="Image" :dark="true" v-model="editingPreOwnedBanners.Image"></ImageUploader>

      </div>

      <div class='col-md-6'>
        <ImageUploader label="Mobile Image" :dark="true" v-model="editingPreOwnedBanners.ImageMobile"></ImageUploader>

      </div>

    </div>
    <div class="row">
      <div class='col-md-4'>
        <label class for='Link'>Link</label>
        <input class='form-control' id='Link' name='Link' v-model='editingPreOwnedBanners.Link' placeholder=' '
          tabindex='0' type='text'>

      </div>
      <div class='col-md-4'>
        <label class for='LinkFriendly'>LinkFriendly</label>
        <input class='form-control' id='LinkFriendly' name='LinkFriendly' v-model='editingPreOwnedBanners.LinkFriendly'
          placeholder=' ' tabindex='0' type='text'>

      </div>
      <div class='col-md-4'>
        <label class for='LastUpdated'>LastUpdated</label>
        <input class='form-control' id='LastUpdated' name='LastUpdated' v-model='editingPreOwnedBanners.LastUpdated'
          placeholder=' ' tabindex='0' type='text'>

      </div>
      <div class=' col-md-4'><label class='' for='ShowText'>ShowText</label>
        <br />
        <b-form-checkbox v-model='editingPreOwnedBanners.ShowText'></b-form-checkbox>
        <div class=''><small></small></div>
      </div>


    </div>
    <br />
    <button @click='save()' type='button' class='btn btn-success'>Save</button>
    <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>
    <br />
    <br />
  </div>

</template>
<script>
import _ from 'lodash';
import ImageUploader from "@/components/custom/ImageUploader.vue";
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });

export default {
  name: 'EditPreOwnedBanners',

  data() {
    return {
      imageBytes: {},
      selectedFile: {},
      originalData: {},
      modifiedData: {},
      uploadedImageData: {},
      uploadedImageUrl: null,
      editingImage: false
    };
  },
  components: {
    ImageUploader
  },
  created() {
    this.originalData = JSON.parse(JSON.stringify(this.editingPreOwnedBanners));

  },

  props: ['editingPreOwnedBanners'],

  methods: {
    toggleView: function () {
      this.uploadedImageUrl = null;
      this.$emit('closeEditPreOwnedBanners');
    },

    save: function () {
      var data = JSON.parse(JSON.stringify(this.editingPreOwnedBanners));
      var payload = {
        data: data,
        image: this.uploadedImageData,
        success: response => {
          this.$emit('editPreOwnedBannersSuccess')
        },
        error: error => { miniToastr['error'](error, 'Error', 1000, null) }
      }
      this.$store.dispatch('editPreOwnedBanners', payload);
    },
    updateImageData: function (value) {
      this.uploadedImageData = value;
    },
    updateImageUrl: function (value) {
      this.uploadedImageUrl = value;
    },
    editImage: function () {
      this.editingImage = !this.editingImage;
    },
    updateImage: function () {
      this.editingImage = !this.editingImage;
    },
    getImageSource(data) {
      if (this.uploadedImageUrl) {
        return this.uploadedImageUrl;
      }
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        if (data.Image) {
          return this.$store.state.cdnUrl + data.Image;
        }
        else {
          return data.Image;
        }
      }
      return '';
    },
    isNull: function (obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
    deletePreOwnedBanners: function () {
      var payload = {
        data: this.editingPreOwnedBanners,
        success: response => {
          this.$emit('editPreOwnedBannersSuccess')
        },
        error: error => { miniToastr['error'](error, 'Error', 1000, null) }
      }
      this.$store.dispatch('deletePreOwnedBanners', payload);
    }
  }
}; 
</script>
<style scoped>
.previewImage {
  max-height: 100px;
}
</style>
