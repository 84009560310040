<template>
  <div class='container'>
    <h2>Add Pre-Owned Banner</h2>
    <button @click='save()' type='button' class='btn btn-success'>Save</button>

    <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>

    <button @click='deletePreOwnedBanners()' type='button' class='btn btn-danger'>Delete</button>

    <div class='row'>
      <div class='col-md-6'>
        <label class for='Heading'>Heading</label>
        <input class='form-control' id='Heading' name='Heading' v-model='newPreOwnedBanners.Heading' placeholder=' '
          tabindex='0' type='text'>

      </div>
      <div class='col-md-6'>
        <label class for='Copy'>Copy</label>
        <input class='form-control' id='Copy' name='Copy' v-model='newPreOwnedBanners.Copy' placeholder=' '
          tabindex='0' type='text'>

      </div>
    </div>
    <div class="row">
      <div class='col-md-6'>
        <ImageUploader label="Image" :dark="true" v-model="newPreOwnedBanners.Image"></ImageUploader>

      </div>

      <div class='col-md-6'>
        <ImageUploader label="Mobile Image" :dark="true" v-model="newPreOwnedBanners.ImageMobile"></ImageUploader>

      </div>

    </div>
    <div class="row">
      <div class='col-md-4'>
        <label class for='Link'>Link</label>
        <input class='form-control' id='Link' name='Link' v-model='newPreOwnedBanners.Link' placeholder=' '
          tabindex='0' type='text'>

      </div>
      <div class='col-md-4'>
        <label class for='LinkFriendly'>LinkFriendly</label>
        <input class='form-control' id='LinkFriendly' name='LinkFriendly' v-model='newPreOwnedBanners.LinkFriendly'
          placeholder=' ' tabindex='0' type='text'>

      </div>
      <div class='col-md-4'>
        <label class for='LastUpdated'>LastUpdated</label>
        <input class='form-control' id='LastUpdated' name='LastUpdated' v-model='newPreOwnedBanners.LastUpdated'
          placeholder=' ' tabindex='0' type='text'>

      </div>
      <div class=' col-md-4'><label class='' for='ShowText'>ShowText</label>
        <br />
        <b-form-checkbox v-model='newPreOwnedBanners.ShowText'></b-form-checkbox>
        <div class=''><small></small></div>
      </div>


    </div>
    <br />
    <button @click='save()' type='button' class='btn btn-success'>Save</button>
    <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>
    <br />
    <br />
  </div>

</template>
<script>
import _ from 'lodash';
import ImageUploader from "@/components/custom/ImageUploader.vue"; 
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  name: 'addPreOwnedBanners',
  created: function() {
  },
  data() {
      return {
          newPreOwnedBanners: {},
        uploadedImageData: {}, 
        uploadedImageUrl: null, 
        editingImage: false 
      }
  },
  components: { 
    ImageUploader 
  }, 
  methods: {
    toggleView: function() {
      this.$emit('closeAddPreOwnedBanners')
    },
	updateImageData: function(value) { 
      this.uploadedImageData = value; 
    }, 
	updateImageUrl: function(value) {  
      this.uploadedImageUrl = value; 
    }, 
    editImage: function() { 
      this.editingImage = !this.editingImage; 
    }, 
    updateImage: function() { 
      this.editingImage = !this.editingImage; 
    }, 
    getImageSource(data) {
      if(this.uploadedImageUrl)
      {
        return this.uploadedImageUrl;
      }
        if (!this.isNull(data) && !this.isNull(data.Image)) {
        if (data.Image) {
            return this.$store.state.cdnUrl + data.Image;
        }
        else {
            return data.Image;
        }
      }
      return '';
    },
    isNull: function(obj) {
        if (typeof obj === 'undefined' || obj === null || obj === 'null') {
            return true;
        }
    return false;
    },
    save: function() {
    var payload = {
          data: this.newPreOwnedBanners,
          image: this.uploadedImageData,
          success: response => {
            this.$emit('addPreOwnedBannersSuccess')
          },
          error: error => { miniToastr['error'](error, 'Error', 1000, null)}
        }
        this.$store.dispatch('addPreOwnedBanners', payload);
    }
  }
}
</script>
<style scoped> 
.previewImage { 
  max-height: 100px; 
} 
</style> 
